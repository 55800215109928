import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/code/src/components/news/layout.jsx";
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SEO from '../../components/seo';
import NewsHeader from '../../components/news/header';
import NewsImg from '../../components/news/img';
export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEO title={"Whoosh and Airvoice launched kick scooters with air quality sensors"} description={"It was a revelation that electric scooter parking lots can work as stationary monitoring stations of high accuracy."} lang={useTranslation().language} link={useI18next().path} mdxType="SEO" />
    <NewsHeader img="whoosh-and-cityair-launched-kick-scooters-with-air-quality-sensors.jpg" tag="news" title="Whoosh and Airvoice launched kick scooters with air quality sensors" description="It was a revelation that electric scooter parking lots can work as stationary monitoring stations of high accuracy." mdxType="NewsHeader" />
    <blank-space />
    <blank-space />
    <p>{`Starting January 21, 2022, eight scooters with air quality sensors became available for rent as part of a pilot project. To collect continuous data, the project was launched in a city with more than 350 thousand people and a climate that allows scooters to work all year round. The miniature boxes with sensors were fixed on the handlebars and almost invisible to the users. Inside the box is a dust aerosol monitor, and two scooters have sensors for nitrogen dioxide, the most dangerous and common gas in the city atmosphere, produced by burning gasoline, coal, garbage, and other solid waste.`}</p>
    <p>{`Kicksharing service Whoosh and air quality monitoring product developer Airvoice have joined forces to achieve a comprehensive view of urban air quality in its spatial and temporal variability. In addition, the project developers envision an effective tool for hyperlocal forecasts and to show citizens the air quality in their neighborhood, on the street, and in the courtyards of apartment houses.`}</p>
    <h3><strong parentName="h3">{`Dmitry Trubitsin`}</strong>{`, founder of Airvoice company:`}</h3>
    <blockquote>
      <p parentName="blockquote">{`The task of building a high-resolution urban air quality map useful, for example, for making urban planning decisions aimed at improving the quality of the urban environment is not a simple one. To solve it, on the one hand, we need mathematical models describing urban air and, on the other hand, reliable direct measurement data. The more data, the better. Stationary observation networks in cities are not always sufficient, so the idea of figuring out the applicability of data obtained by mobile mini-stations placed on scooters sounds very tempting and promising. We plan in six months to analyze the data from the experimental e-scooters, and if some of our hypotheses will be confirmed, we will equip the entire Whoosh fleet with our mobile mini-stations.`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Housing design - compact and vandal-proof`}</h2>
    <p>{`Whoosh engineers designed special housing to integrate the monitoring device into the rack of an electric scooter. The sensors were placed inside the housing, and then it was inserted into the slot for the lock on the rack.`}</p>
    <p>{`Airvoice developers created software to process signals from the module's measurement sensors, and Whoosh specialists added software that transmits data from the Airvoice module to the Cloud platform. The data is collected continuously, in real-time: every time the IoT module sends the coordinate of its location, it sends data from the sensor as well. On average, once every 15 seconds.`}</p>
    <hr></hr>
    <h2>{`The design of the mini-station is the same as the large one but in a compact case`}</h2>
    <p>{`Airvoice manufactures stationary stations measuring 16×32×46 cm, which are used to monitor air quality in factories, cities, and industrial facilities. To attach it to an e-scooter, the station had to be smaller and lighter so that it would fit seamlessly into the design of the scooter and not interfere with the users, but remain a reliable source of pollutant data.`}</p>
    <p>{`Airvoice engineers structurally redesigned and removed the built-in battery from the station, connecting it to a kick scooter battery. They developed a mini-board and designed a set of compact sensors for measuring dust aerosol concentrations, nitrogen dioxide, and a weather module.`}</p>
    <p>{`They also designed a data transmitter that "polls" the sensor once every 15 seconds, rather than once every minute, as is the case with the "big," stationary Airvoice air quality monitors. This requirement arose due to the specifics of the project: in one minute, the e-scooter has time to travel up to 400 meters, during which time-critical information about the state of the air is collected.`}</p>
    <NewsImg img="cityair-mini-station.jpg" mdxType="NewsImg" />
    <hr></hr>
    <h2>{`Already analyzed 1,000,000 points`}</h2>
    <p>{`Airvoice analysts analyzed the data from 8 scooters during the first week of operation - from January 21 to 28. During this time the scooters managed to cover the entire city center.`}</p>
    <p>{`The point is the concentration of pollutants recorded in a given location. Three colors are used to indicate pollution - yellow, green, and red, which correspond to the AQI (Air Quality Index) scale from 1 to 10, where 10 is very dangerous for health, and 1 - is perfectly clean and completely safe even for people with diseases of the cardiovascular system and lungs. In the data array, each point is labeled with GPS coordinate, timestamp, and e-scooter ID.`}</p>
    <NewsImg img="cityair-maps.jpg" mdxType="NewsImg" />
    <p>{`Due to the fact that scooters drive slowly and not only on the roadside but also on bicycle lanes and into courtyards, it is already possible to conclude from the analyzed data how far the impact of freeways on urban air extends. In addition, several interesting hypotheses emerged from the analysis.`}</p>
    <h3>{`Hypothesis #1: You can use a kick scooter to find urban canyons and take steps to improve air quality.`}</h3>
    <p>{`An urban canyon is a place where closely packed houses create an environment conducive to the accumulation and stagnation of impurities. An entire city can be clean, and the canyon at that moment will have poor air quality. Once in such a "stagnant" place, mini-stations on e-scooters can pick up quality variations that will be clearly visible in the data. This could be the call to action for authorities and citizens, such as more green space and green roofs.`}</p>
    <h3>{`Hypothesis #2 - Determine the clean side of the street`}</h3>
    <p>{`Once, during the analyzed week, the analysts observed the following picture: a scooter travels back and forth along the same road for 10 minutes, and the values it measures in terms of AQI vary greatly: when driving one way the mini-station showed AQI 3, and when the same e-scooter moved in the opposite direction - AQI became equal to 7.`}</p>
    <p>{`Airvoice plans to monitor such cases and collect statistics on them in order to confirm the pattern: dust aerosol particles accumulate on one of the road edges. It is easy to predict the wind direction in the seaside town, we can assume that in the evening the wind blows from the sea, and in the morning breeze comes down from the mountains and drives the dust aerosol particles in the direction of the sea. If the analysis of the accumulated data confirms this hypothesis, then based on this information it will be possible to make recommendations to mothers with baby carriages, athletes-runners, allergists, asthmatics, and everyone who cares about health: in the morning walk on the side of the road that leads from the shore, and in the evening – to walk on the side of the road which is closer to the shore.`}</p>
    <p>{`This information will also be useful for the city administration: you can optimize street cleaning based on data about the pollution collected by mini-stations and thereby improve air quality and save on the work of sprinklers and sweepers.`}</p>
    <h3>{`Hypothesis #3: Create a dense network of virtual stationary stations`}</h3>
    <p>{`A big plus of e-scooters is that they can not only move but also stand upright. On the map, in addition to the trajectories of moving scooters, you can also see areas of their accumulation (cluster points), i.e. parking lots.`}</p>
    <NewsImg img="news-roundabout.jpg" mdxType="NewsImg" />
    <p>{`According to the hypothesis, a kick-scooter can behave as a virtual stationary station at a cluster point and even be calibrated relative to others. If it is possible to confirm the validity of the hypothesis, the data from mini-stations on scooters will be no less reliable than from stationary stations, because they will confirm the accuracy of measurements and insure each other against failures, and sensor anomalies, etc.`}</p>
    <p>{`E-scooter parking lots could be set up in places where air quality needs to be measured and then moved to other locations after an action is taken and air quality improves.`}</p>
    <h2>{`Global experience with air quality monitoring using stations on mobile platforms`}</h2>
    <p>{`Several scientific and commercial projects are known in the world when stations and monitors were attached to a moving object: for example, researchers from the Massachusetts Institute of Technology `}<a parentName="p" {...{
        "href": "http://senseable.mit.edu/papers/pdf/20200601_deSouza-etal_AirQuality_SCS.pdf"
      }}>{`installed sensors on garbage trucks`}</a>{`, Swiss researchers used cabs and streetcars, and Google sought volunteers among pedestrians who wore sensors on themselves. The main conclusion that almost all the experimenters managed to draw is that a sensor on a mobile platform can provide detailed information about the quality of air in the city`}</p>
    <p>{`However, these studies were short-lived and have not been continued. The longest and most fruitful is the project of the World Athletics Association, which since 2018 has been studying air with sensors on mobile platforms during marathons to find correlations between air quality and athletes' performance. They already have the first results: a large number of records set during the race in the Polish city of Gdynia are `}<a parentName="p" {...{
        "href": "https://worldathletics.org/news/press-releases/world-half-gdynia-2020-air-quality"
      }}>{`attributed to good air quality`}</a>{`. The World Athletics project continues, and its results will be used to set air quality standards for major track and field events.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      